var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showHistory
        ? _c(
            "div",
            {
              staticStyle: {
                position: "relative",
                "z-index": "1",
                "margin-top": "5px",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  staticStyle: { "margin-right": "20px" },
                  on: {
                    change: function ($event) {
                      return _vm.changeTypeMethod()
                    },
                  },
                  model: {
                    value: _vm.dataSearch.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "type", $$v)
                    },
                    expression: "dataSearch.type",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "month" } }, [
                    _vm._v("Xem theo tháng"),
                  ]),
                  _c("el-radio", { attrs: { label: "date" } }, [
                    _vm._v("Xem theo ngày"),
                  ]),
                ],
                1
              ),
              _vm.showMonth
                ? _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px", "margin-right": "5px" },
                      attrs: { clearable: "", placeholder: "Chọn tháng" },
                      on: {
                        change: function ($event) {
                          return _vm.searchHeaderMethod()
                        },
                      },
                      model: {
                        value: _vm.dataSearch.month,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataSearch, "month", $$v)
                        },
                        expression: "dataSearch.month",
                      },
                    },
                    _vm._l(_vm.monthList, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm.showDate
                ? _c("el-date-picker", {
                    staticClass: "input-common",
                    staticStyle: { width: "265px" },
                    attrs: {
                      "value-format": "yyyy-MM-dd",
                      format: "dd-MM-yyyy",
                      type: "daterange",
                      "unlink-panels": "",
                      "range-separator": "-",
                      "start-placeholder": "Ngày bắt đầu",
                      "end-placeholder": "Ngày kết thúc",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.changeDateMethod()
                      },
                    },
                    model: {
                      value: _vm.dataSearch.dateList,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataSearch, "dateList", $$v)
                      },
                      expression: "dataSearch.dateList",
                    },
                  })
                : _vm._e(),
              _c(
                "el-select",
                {
                  staticStyle: { width: "150px" },
                  attrs: { clearable: "", placeholder: "Chọn nguồn" },
                  on: {
                    change: function ($event) {
                      return _vm.changeTypeMethod()
                    },
                  },
                  model: {
                    value: _vm.dataSearch.typeCashbook,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "typeCashbook", $$v)
                    },
                    expression: "dataSearch.typeCashbook",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { value: "KID", label: "Học phí" },
                  }),
                  _c("el-option", {
                    attrs: { value: "EMP", label: "Công lương" },
                  }),
                  _c("el-option", {
                    attrs: { value: "SCH", label: "Thu chi nội bộ" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm.showAdd
        ? _c("div", {
            staticStyle: {
              position: "relative",
              "z-index": "1",
              "margin-top": "5px",
              height: "40px",
            },
          })
        : _vm._e(),
      _c(
        "el-tabs",
        {
          staticStyle: { "margin-top": "-20px" },
          attrs: { type: "card" },
          on: { "tab-click": _vm.handleClickTab },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Năm hiện tại", name: "tab1" } },
            [
              _c(
                "div",
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: {
                            background: "#409eff",
                            "border-top-left-radius": "5px",
                            "border-bottom-left-radius": "5px",
                          },
                          attrs: { span: 5 },
                        },
                        [
                          _vm._v("\n            Số dư đầu kỳ:\n            "),
                          _c("span", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("formatCurrencyNew")(
                                    _vm.responseData.moneyStart
                                  )
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { background: "#67c23a" },
                          attrs: { span: 5 },
                        },
                        [
                          _vm._v("\n            Tổng thu:\n            "),
                          _c("span", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("formatCurrencyNew")(
                                    _vm.responseData.moneyIn
                                  )
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { background: "#f56c6c" },
                          attrs: { span: 5 },
                        },
                        [
                          _vm._v("\n            Tổng chi:\n            "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatCurrencyNew")(
                                  _vm.responseData.moneyOut
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { background: "#e6a23c" },
                          attrs: { span: 4 },
                        },
                        [
                          _vm._v("\n            Thu-Chi:\n            "),
                          _c("span", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("formatCurrencyNew")(
                                    _vm.responseData.moneyIn -
                                      _vm.responseData.moneyOut
                                  )
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: {
                            background: "#409eff",
                            "border-top-right-radius": "5px",
                            "border-bottom-right-radius": "5px",
                          },
                          attrs: { span: 5 },
                        },
                        [
                          _vm._v("\n            Số dư cuối kỳ:\n            "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatCurrencyNew")(
                                  _vm.responseData.moneyEnd
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("div", {
                    staticStyle: {
                      "margin-bottom": "10px",
                      "font-weight": "bold",
                      "font-size": "17px",
                    },
                  }),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingDataHistory,
                          expression: "loadingDataHistory",
                        },
                      ],
                      ref: "multipleTable",
                      attrs: {
                        "empty-text": _vm.textTable,
                        "element-loading-text": _vm.$tableLoading,
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-background": "rgba(255,255,255, 0)",
                        "highlight-current-row": "",
                        data: _vm.responseData.dataList,
                        "cell-style": _vm.tableRowStyleHistory,
                        "header-cell-style": _vm.tableHeaderColor,
                        "max-height": _vm.$tableMaxHeightSmall,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          fixed: "",
                          label: "STT",
                          width: "50",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "170",
                          fixed: "",
                          align: "center",
                          label: "Thời gian thực",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatDateTime")(
                                        scope.row.createdDate
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "170",
                          fixed: "",
                          align: "center",
                          label: "Thời gian nhập",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm._f("formatDate")(scope.row.date))
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Loại",
                          align: "center",
                          "min-width": "170",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.category == "in"
                                  ? _c("span", [_vm._v("Nạp tiền")])
                                  : _vm._e(),
                                scope.row.category == "out"
                                  ? _c("span", [_vm._v("Rút tiền")])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Nguồn",
                          align: "center",
                          "min-width": "170",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.type == "KID"
                                  ? _c("span", [_vm._v("Học phí")])
                                  : scope.row.type == "EMP"
                                  ? _c("span", [_vm._v("Công lương")])
                                  : scope.row.type == "SCH"
                                  ? _c("span", [_vm._v("Thu chi nội bộ")])
                                  : _c("span", [
                                      scope.row.origin == "UNAPPROVED"
                                        ? _c("span", [_vm._v("Bỏ duyệt khoản")])
                                        : scope.row.origin == "DELETE"
                                        ? _c("span", [_vm._v("Xóa khoản")])
                                        : _vm._e(),
                                    ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Mã hóa đơn",
                          align: "center",
                          prop: "code",
                          "min-width": "125",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Số tiền",
                          align: "right",
                          "min-width": "150",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.money == 0
                                  ? _c("span", [_vm._v("0")])
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatCurrencyNew")(
                                            scope.row.money
                                          )
                                        )
                                      ),
                                    ]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "paging-click row-data",
                      staticStyle: { float: "right", "margin-bottom": "10px" },
                    },
                    [
                      _c("el-pagination", {
                        staticStyle: { float: "left" },
                        attrs: {
                          background: "",
                          "current-page": _vm.pageNumber,
                          "page-sizes": _vm.$pageListDefaultNew,
                          "page-size": _vm.maxPageItem,
                          layout: _vm.$pageLayoutDefault,
                          total: _vm.pageTotal,
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.handleCurrentChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c("el-tab-pane", { attrs: { label: "Danh sách", name: "tab2" } }, [
            _c(
              "div",
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingData,
                        expression: "loadingData",
                      },
                    ],
                    ref: "multipleTable",
                    attrs: {
                      "empty-text": _vm.textTable,
                      "element-loading-text": _vm.$tableLoading,
                      "element-loading-spinner": "el-icon-loading",
                      "element-loading-background": "rgba(255,255,255, 0)",
                      "highlight-current-row": "",
                      data: _vm.responseList,
                      "cell-style": _vm.tableRowStyle,
                      "header-cell-style": _vm.tableHeaderColor,
                      "max-height": _vm.$tableMaxHeight,
                      border: "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "index",
                        fixed: "",
                        label: "STT",
                        width: "50",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "170",
                        fixed: "",
                        align: "center",
                        label: "Năm",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.year))]),
                              _c("div", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm._f("formatDate")(scope.row.startDate)
                                    ) +
                                    " -\n                " +
                                    _vm._s(
                                      _vm._f("formatDate")(scope.row.endDate)
                                    ) +
                                    "\n              "
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Trạng thái",
                        align: "center",
                        width: "90",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.locked
                                ? _c("span", [_vm._v("Đã khóa")])
                                : _c("span", [_vm._v("Chưa khóa")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Số dư đầu kỳ",
                        align: "right",
                        "min-width": "130",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.moneyStart == 0
                                ? _c("span", [_vm._v("0")])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatCurrency")(
                                          scope.row.moneyStart
                                        )
                                      )
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Tổng tiền thu",
                        align: "right",
                        "min-width": "130",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.moneyIn == 0
                                ? _c("span", [_vm._v("0")])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatCurrency")(
                                          scope.row.moneyIn
                                        )
                                      )
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Tổng tiền chi",
                        align: "right",
                        "min-width": "130",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.moneyOut == 0
                                ? _c("span", [_vm._v("0")])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatCurrency")(
                                          scope.row.moneyOut
                                        )
                                      )
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Tổng thu - Tổng chi",
                        align: "right",
                        "min-width": "140",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.moneyIn - scope.row.moneyOut == 0
                                ? _c("span", [_vm._v("0")])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatCurrency")(
                                          scope.row.moneyIn - scope.row.moneyOut
                                        )
                                      )
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Số dư cuối kỳ",
                        align: "right",
                        "min-width": "130",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.moneyStart +
                                (scope.row.moneyIn - scope.row.moneyOut) ==
                              0
                                ? _c("span", [_vm._v("0")])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatCurrency")(
                                          scope.row.moneyStart +
                                            (scope.row.moneyIn -
                                              scope.row.moneyOut)
                                        )
                                      )
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm.checkPermission(["cashInternal_cashbook_history"])
                      ? _c("el-table-column", {
                          attrs: {
                            label: "Tác vụ",
                            width: "100",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "success",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.cashBookHistoryDialogMethod(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "Chi\n                tiết\n              "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4183007758
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c("CashBookHistory", {
                  ref: "CashBookHistory",
                  attrs: { dialogVisible: _vm.showDetailDialog },
                  on: {
                    "dialog-close": function ($event) {
                      return _vm.closeDetailDialogMethod()
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }